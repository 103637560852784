import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/reservaurbana',
    name: 'reservaurbana',
    component: () => import('../views/reservaurbana/Home.vue')
  },
  {
    path: '/reservaurbana/caracteristicas',
    name: 'reservaurbana-caracteristicas',
    component: () => import('../views/reservaurbana/Caracteristicas.vue')
  },
  {
    path: '/reservaurbana/galeria',
    name: 'reservaurbana-galeria',
    component: () => import('../views/reservaurbana/Galeria.vue')
  },
  {
    path: '/reservaurbana/plantas',
    name: 'reservaurbana-plantas',
    component: () => import('../views/reservaurbana/Plantas.vue')
  },
  {
    path: '/reservaurbana/brochure',
    name: 'reservaurbana-brochure',
    component: () => import('../views/reservaurbana/Brochure.vue')
  },
  {
    path: '/reservaurbana/ubicacion',
    name: 'reservaurbana-ubicación',
    component: () => import('../views/reservaurbana/Ubicacion.vue')
  },
  {
    path: '/reservaurbana/tourvirtual',
    name: 'reservaurbana-tourVirtual',
    component: () => import('../views/reservaurbana/Tourvirtual.vue')
  },
  {
    path: '/losleones',
    name: 'losleones',
    component: () => import('../views/losleones/Home.vue')
  },
  {
    path: '/losleones/caracteristicas',
    name: 'losleones-caracteristicas',
    component: () => import('../views/losleones/Caracteristicas.vue')
  },
  {
    path: '/losleones/galeria',
    name: 'losleones-galeria',
    component: () => import('../views/losleones/Galeria.vue')
  },
  {
    path: '/losleones/plantas',
    name: 'losleones-plantas',
    component: () => import('../views/losleones/Plantas.vue')
  },
  {
    path: '/losleones/brochure',
    name: 'losleones-brochure',
    component: () => import('../views/losleones/Brochure.vue')
  },
  {
    path: '/losleones/ubicacion',
    name: 'losleones-ubicación',
    component: () => import('../views/losleones/Ubicacion.vue')
  },
  {
    path: '/losleones/tourvirtual',
    name: 'losleones-tourVirtual',
    component: () => import('../views/losleones/Tourvirtual.vue')
  },
  {
    path: '/pocuro',
    name: 'pocuro',
    component: () => import('../views/pocuro/Home.vue')
  },
  {
    path: '/pocuro/caracteristicas',
    name: 'pocuro-caracteristicas',
    component: () => import('../views/pocuro/Caracteristicas.vue')
  },
  {
    path: '/pocuro/galeria',
    name: 'pocuro-galeria',
    component: () => import('../views/pocuro/Galeria.vue')
  },
  {
    path: '/pocuro/plantas',
    name: 'pocuro-plantas',
    component: () => import('../views/pocuro/Plantas.vue')
  },
  {
    path: '/pocuro/brochure',
    name: 'pocuro-brochure',
    component: () => import('../views/pocuro/Brochure.vue')
  },
  {
    path: '/pocuro/ubicacion',
    name: 'pocuro-ubicación',
    component: () => import('../views/pocuro/Ubicacion.vue')
  },
  {
    path: '/pocuro/tourvirtual',
    name: 'pocuro-tourVirtual',
    component: () => import('../views/pocuro/Tourvirtual.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
