<template>
  <div class="main-container" v-if="showMainContainer">
    <div @click="handleRouteChange">
      <router-link to="/" class="link" >
        <div class="flex-column justify-content-center align-items-center text-center" >
          <img src="https://touchdisplay.cl/img-pantallas/logo-besalco.svg" width="250px" alt="Logotipo" />
          <h1 class="animate__animated animate__pulse animate__infinite mt-3" >Haga click para comenzar</h1>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-hand-click animate__animated animate__pulse animate__infinite" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
            <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5" />
            <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
            <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
            <path d="M5 3l-1 -1" />
            <path d="M4 7h-1" />
            <path d="M14 3l1 -1" />
            <path d="M15 6h1" />
          </svg>
        </div>
      </router-link>
    </div>
    
  </div>
  <router-view v-if="homeView" />
</template>
<script>
export default {
  data() {
    return {
      showMainContainer: true,
      homeView: false
    };
  },
  methods: {
    handleRouteChange() {
      this.showMainContainer = false
      this.homeView = true
    }
  }
};
</script>
<style>

h1 {
  cursor: pointer;
  color: #fff;
  font-size: 1.2em;
}

link {
  text-decoration: none !important;
}
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
